.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

._tagInput_16veo_1.ant-input {
  padding: 0 0 0 10px !important
}
